import React, { useState, useEffect } from 'react';
import { CategoryList } from './Categoria';
import { loadingFunc, itemcount, cartItems } from '../assets/cart.js';



export const Home = () => {
  const [products, setProducts] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [sortOrder, setSortOrder] = useState('price-asc');
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [cartProducts, setCartProducts] = useState([]);

  useEffect(() => {
    // Llama a la función cuando el componente se monte
    itemcount();
}, []);

  useEffect(() => {
    fetch('https://testreact.tdestudiodesign.com/backend/index.php?type=products&method=get')
      .then((res) => {
        if (!res.ok) {
          throw new Error('Error en la respuesta del servidor');
        }
        return res.json();
      })
      .then((data) => {
        console.log("Productos obtenidos:", data);  // Verifica los productos obtenidos
        setProducts(data);
        setFilteredProducts(data); // Inicializar con todos los productos
      })
      .catch((error) => {
        console.error('Error fetching products:', error);
      });
  }, []);

  const sortProducts = (products, sortOrder) => {
    return products.slice().sort((a, b) => {
      if (sortOrder === 'price-asc') {
        return (a.precio_producto || 0) - (b.precio_producto || 0);
      } else if (sortOrder === 'price-desc') {
        return (b.precio_producto || 0) - (a.precio_producto || 0);
      } else if (sortOrder === 'name-asc') {
        return (a.nombre_producto || '').localeCompare(b.nombre_producto || '');
      } else if (sortOrder === 'name-desc') {
        return (b.nombre_producto || '').localeCompare(a.nombre_producto || '');
      } else if (sortOrder === 'desc-asc') {
        return (b.descuento_producto || 0) - (a.descuento_producto || 0);
      } else {
        return 0; // No sort
      }
    });
  };

  const handleSortChange = (e) => {
    setSortOrder(e.target.value);
  };

  const handleCategoryChange = (selectedCategories) => {
    console.log("Categorías seleccionadas:", selectedCategories);
    setSelectedCategories(selectedCategories);

    if (selectedCategories.length === 0) {
        setFilteredProducts(products);
        return;
    }

    const filteredProducts = products.filter(product => {
        console.log("Revisando producto:", product);

        // Verifica la categoría padre
        const categoryIds = product.categorias_producto.split('+').map(id => parseInt(id));
        const isParentCategoryMatch = categoryIds.some(id => selectedCategories.includes(id));
        // Verifica las categorías hijas, manejando el caso en que categorias_hijas sea null
        let isChildCategoryMatch = false;
        if (product.categorias_hijas && typeof product.categorias_hijas === 'string') {
          
            const childCategoryIds = product.categorias_hijas.split(',').map(id => parseInt(id));
            isChildCategoryMatch = childCategoryIds.some(id => selectedCategories.includes(id));
        }
        

        return isParentCategoryMatch || isChildCategoryMatch;
      });

    console.log("Productos filtrados:", filteredProducts);
    setFilteredProducts(filteredProducts);
};

  const sortedProducts = sortProducts(filteredProducts, sortOrder);
  
 
useEffect(() => {
    const cartArrayProd = JSON.parse(localStorage.getItem('cart') || '[]');
    setCartProducts(cartArrayProd);
  }, []); // Se ejecuta solo una vez cuando el componente se monta

  // Función para obtener la cantidad de un producto en el carrito
  const getProductQuantityInCart = (productId) => {
    const productInCart = cartProducts.find((item) => item.id === productId);
    return productInCart ? productInCart.qty : 0;
  };
  
  function addtocart1(nombre,id, precio,descuento,taxes){
    let cant;

const inputQuantity = document.querySelector('#inputQuantity');

if (inputQuantity) {
    cant = inputQuantity.value;

} else {
    cant = 1;
} 
    const qty = cant;
    const name = nombre;
    const price = precio;
    const desc = descuento;
    const tax = taxes;
    const id_e = id;
console.log(id);
    const localcart = { 
        'id': id_e,
        'name': name,
        'price': price,
        'qty': qty,
        'tax': tax,
        'desc': desc
    };            
    const cartArrayProd = JSON.parse(localStorage.getItem("cart") || "[]");
    if(cartArrayProd.find(x => x.id === id)){
        let newQty = cartArrayProd.filter(x => x.id === id);
        newQty[0]['qty'] = Number(qty) + Number(newQty[0].qty);
        localStorage.setItem("cart", JSON.stringify(cartArrayProd));
    }else{
        cartArrayProd.push(localcart);
        localStorage.setItem("cart", JSON.stringify(cartArrayProd));    
    }
    if (setCartProducts) {
      setCartProducts([...cartArrayProd]);
      cartItems();
    }

}



  return (

    <div className="w-100 d-flex flex-row">
          <br/>

      <div className="w-25 px-5 my-3">
        <CategoryList onCategoryChange={handleCategoryChange} />
      </div>
      <div className="w-75 px-5 my-3">
        <section className="features4 cid-tM5FEog0YI" id="features5-26">
          <div className="container">
            <div className="row" id="productos">
              <div className='row my-3'>
                <div className="col-9">
                  <span>Total de productos: {sortedProducts.length}</span>
                </div>
                <div className="col-3">
                  <select value={sortOrder} onChange={handleSortChange}>
                    <option value="price-asc">Menor Precio</option>
                    <option value="price-desc">Mayor Precio</option>
                    <option value="desc-asc">Mayor Descuento</option>
                    <option value="name-asc">Alfabeticamente (A-Z)</option>
                    <option value="name-desc">Alfabeticamente (Z-A)</option>
                  </select>
                </div>
              </div>
              <br/>
              <br/>
              {/* Renderiza los productos filtrados */}
              {sortedProducts.map((product) => (
                

                <div key={product.id_producto} id="col" className="col-3 col-lg-3">
                  <div id="card" className="card-wrapper">
                    <div id="row" className="row">
                      <div className="item-wrapper">
                        <div className="item-img">
                          <a href={`producto.php?id_producto=${product.id_producto}`}>
                            <span className="btn p-2 btn-light position-absolute z-3"> %-{product.descuento_producto}.</span>
                            <img src={`https://safewebestudio.com/admin/service/products/images/${product.imagen_principal_producto}`} width="150" height="150" alt="Mobirise Website Builder" />
                          </a>
                        </div>
                        <div className="item-content">
                          <span className="align-center">{product.nombre_categoria}</span>
                          <h5 style={{ height: "50px", overflowY: "hidden" }} className="item-title mbr-fonts-style display-5">
                            <strong>
                              <a href={`producto.php?id_producto=${product.id_producto}`} className="text-primary fs-5">{product.nombre_producto}</a>
                            </strong>
                          </h5>
                          <h6 className="item-subtitle mbr-fonts-style mt-1">
                            <span className="text-decoration-line-through fs-6">AR$ {product.precio_producto}.-</span>&nbsp;&nbsp;
                            <span className="fs-6" id="prodDesc" >AR$ {(product.precio_producto - (product.precio_producto * product.descuento_producto) / 100)}.-</span>
                          </h6>
                        </div>
                        <div className="mbr-section-btn justify-content-evenly d-flex item-footer mt-2">
                          <a href={`/producto?id=${product.id_producto}`} className="w-50 btn py-2 rounded btn-primary">Ver</a>
                          <button className="cd-button btn w-50 py-2 px-3 mx-0 rounded btn-success"
                              onClick={() => {
                                loadingFunc(product.id_producto);
                                addtocart1(
                                  product.nombre_producto, 
                                  product.id_producto, 
                                  product.precio_producto - (product.precio_producto * product.descuento_producto) / 100, 
                                  product.descuento_producto,
                                  product.Impuestos_producto
                                );
                                
                              }}                          >
                            <i id={product.id_producto} className="bi-cart-plus me-1"></i><span id={"prod" + product.id_producto} className="cartcant2">{getProductQuantityInCart(product.id_producto)}</span>
                          </button>
                        </div>
                        <span className="lead fs-6 fw-medium align-center">stock disponible: <em>{product.stock_producto}</em></span>

                      </div>
                    </div>
                  </div>
                  <br />
                </div>
              ))}
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default Home;
