import React, { useEffect, useState } from 'react';
//import { useParams } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import {cartItems} from '../assets/cart.js'


export const ProductDetail = () => {
  //const { id } = useParams();
  const [product, setProduct] = useState(null);
  const location = useLocation();
  const[value, setValue] = useState(1); 
  const [cartProducts, setCartProducts] = useState([]);

  function addtocart(id, name, price, value, tax, desc ) {
      
      console.log('qty: '+value);
      console.log('name: '+name);
      console.log('price: '+price);
      console.log('desc: '+desc);
      console.log('tax: '+tax);
      console.log('id: '+id);
      let qty = value;
  
      const localcart = { 
        'id': id,
        'name': name,
        'price': price,
        'qty': Number(qty),
        'tax': tax,
        'desc': desc

      };            
      const cartArrayProd = JSON.parse(localStorage.getItem("cart") || "[]");
      if(cartArrayProd.find(x => x.id === id)){
          let newQty = cartArrayProd.filter(x => x.id === id);
          newQty[0]['qty'] = Number(qty) + Number(newQty[0].qty);
          localStorage.setItem("cart", JSON.stringify(cartArrayProd));
      }else{
          cartArrayProd.push(localcart);
          localStorage.setItem("cart", JSON.stringify(cartArrayProd));    
      }
      if (setCartProducts) {
        setCartProducts([...cartArrayProd]);
        cartItems();
      }
  }
  
  // Esto te dará un objeto URLSearchParams
  const queryParams = new URLSearchParams(location.search);

  // Para obtener un parámetro específico, por ejemplo "paramName"
  const id = queryParams.get('id');
  useEffect(() => {
    const cartArrayProd = JSON.parse(localStorage.getItem('cart') || '[]');
    setCartProducts(cartArrayProd);
  }, []); 
  useEffect(() => {
    fetch(`https://testreact.tdestudiodesign.com/backend/index.php?type=products&id=${id}&method=get`)
      .then((response) => response.json())
      .then((data) => {
        setProduct(data);
      })
      .catch((error) => {
        console.error("Error fetching product:", error);
      });
  }, [id]);

  if (!product) {
    return <div>Loading...</div>;
  }
const final = product.precio_producto - (product.precio_producto * product.descuento_producto) / 100;
const descuento = (product.precio_producto * product.descuento_producto) / 100;



const getProductQuantityInCart = (productId) => {
  const productInCart = cartProducts.find((item) => item.id === productId);
  
  // Verifica si el producto existe en el carrito
  if (productInCart) {
    // Verifica si qty está definida y es mayor a 0
    if (productInCart.qty > 0) {
      return productInCart.qty + ' En carrito';
    } else {
      return 'Añadir';
    }
  } else {
    // Si el producto no está en el carrito
    return 'Añadir';
  }
};
  return (

      <>
        <section className="py-3">
          <div className="container px-4 px-lg-5 my-3">
            <div className="row gx-4 gx-lg-5 align-items-center">
              <div className="col-md-6">
                <div id="carouselExample" className="carousel slide">
                  <div className="carousel-inner">
                    <div className="carousel-item active">
                      <img src={`https://safewebestudio.com/admin/service/products/images/${product.ppal}`} className="d-block w-60" alt="..." />
                    </div>
                    {[product.galery, product.galery1, product.galery2, product.galery3].map((image, index) => (
                      <div key={index} className="carousel-item">
                        <div className="d-flex align-items-center justify-content-center back w-100 h-100 position-absolute">
                          <button type="button" className="imag btn position-absolute justify-content-center" data-bs-toggle="modal" data-bs-target="#exampleModalToggle">
                            <i className="bi bi-zoom-in"></i>
                          </button>
                        </div>
                        <img src={`https://safewebestudio.com/admin/service/products/images/${image}`} className="d-block w-60" alt="..." />
                      </div>
                    ))}
                  </div>
                  <button className="carousel-control-prev" type="button" data-bs-target="#carouselExample" data-bs-slide="prev">
                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Previous</span>
                  </button>
                  <button className="carousel-control-next" type="button" data-bs-target="#carouselExample" data-bs-slide="next">
                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Next</span>
                  </button>
                </div>
                <div className="modal bg-dark fade" id="exampleModalToggle" aria-hidden="true" aria-labelledby="exampleModalToggleLabel" tabIndex="-1">
                  <div className="modal-dialog modal-xl modal-dialog-centered">
                    <div className="modal-content bg-transparent border-0">
                      <div className="modal-header border-0 px-5 z-3">
                        <button type="button" className="btn-close bg-light p-3" data-bs-dismiss="modal" aria-label="Close"></button>
                      </div>
                      <div className="modal-body">
                        <div id="carousel" className="carousel slide">
                          <div className="carousel-inner">
                            {[product.ppal, product.galery, product.galery1, product.galery2, product.galery3].map((image, index) => (
                              <div key={index} className={`carousel-item ${index === 0 ? 'active' : ''}`}>
                                <img src={`https://safewebestudio.com/admin/service/products/images/${image}`} className="d-block w-80" alt="..." />
                              </div>
                            ))}
                          </div>
                          <button className="carousel-control-prev position-fixed" type="button" data-bs-target="#carousel" data-bs-slide="prev">
                            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span className="visually-hidden">Previous</span>
                          </button>
                          <button className="carousel-control-next position-fixed" type="button" data-bs-target="#carousel" data-bs-slide="next">
                            <span className="carousel-control-next-icon" aria-hidden="true"></span>
                            <span className="visually-hidden">Next</span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="small mb-1 align-right">Codigo: {product.codigo_producto} &nbsp;||&nbsp; Categoria: {product.prod_cat}</div>
                <h1 className="display-5 fs-5 fw-bolder text-center" id="prodName">{product.nombre_producto}</h1>
                <div className="fs-5 mb-5 align-middle">
                  <span id="desc" className="btn p-2 btn-success"> % -{product.descuento_producto}</span>
                  <span  className="text-decoration-line-through fs-5 " id="prodPrice"> AR$ {product.precio_producto}.-</span>
                  <span className="fs-5 ">AR$</span> <span className="fs-5 " id="prodDesc">{final}</span> <span>.-</span>
                </div>
                <p className="lead fs-6 fw-medium align-center"><em><strong>Te lo dejamos armado, configurado y listo para que atraigas clientes en 72 - 96 hs</strong></em></p>
                <p className="lead">{product.descuento_producto}</p>
                <div className="mbr-section-btn justify-content-evenly d-flex item-footer mt-2"> 
                <p className="lead fs-6 fw-medium align-center">stock disponible: <em>{product.stock_producto}</em></p>
                </div>
                <div className="d-flex align-items-center justify-content-center flex-row flex-wrap">
                  <input type="hidden" name="idprod" value={product.id_producto} id="idprod" />
                  <input type="hidden" name="tax" value={product.Impuestos_producto} id="tax" />
                  <input className="form-control text-center me-3" id="inputQuantity" type="number"  value={value !== undefined ? value : 1} onChange={(e) => {setValue(e.target.value)}} style={{ width: '6rem', height: '3rem' }} />
                  <button className="btn btn-success flex-shrink-0 w-50" 
                  onClick={() => {
                    cartItems();
                    addtocart(
                      product.id_producto, 
                      product.nombre_producto, 
                      final, 
                      value,
                      product.Impuestos_producto,
                      descuento 
                       );
                    cartItems();
                    }}
                    id={product.id_producto} type="button">
                    <i className="bi-cart-fill me-1"></i>
                    <span id={"prod" + product.id_producto} className="cartcant2">{getProductQuantityInCart(product.id_producto)}</span></button>
                  <a className="btn btn-info display-4 fs-6 border-0" style={{ color: '#fff', backgroundColor: '#009900', width: '45%' }} href="https://wa.me/541151215645"><span className="socicon mx-2 socicon-whatsapp mbr-iconfont mbr-iconfont-btn"></span>Contactar</a>
                  <a className="btn btn-success border-0 display-4 fs-6" style={{ color: '#fff', backgroundColor: '#06515b', width: '45%' }} id="informacion" href="#informacion"><i className="bi bi-info-circle"></i> Ver detalles</a>
                </div>
              </div>
            </div>
          </div>
        </section>
  
        
  
        <div className="dual">
          <section className="py-2 bg-white w-100">
            <div className="container px-4 px-lg-3 mt-3">
              <div className="d-flex align-items-start w-100">
                <div className="nav flex-column nav-pills me-3 bg-light p-5 h-70p" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                  <button className="nav-link active" id="v-pills-profile-tab" data-bs-toggle="pill" data-bs-target="#v-pills-profile" type="button" role="tab" aria-controls="v-pills-profile" aria-selected="true">Información Técnica</button>
                  <button className="nav-link" id="v-pills-home-tab" data-bs-toggle="pill" data-bs-target="#v-pills-home" type="button" role="tab" aria-controls="v-pills-home" aria-selected="false">Descripción</button>
                  <button className="nav-link" id="v-pills-messages-tab" data-bs-toggle="pill" data-bs-target="#v-pills-messages" type="button" role="tab" aria-controls="v-pills-messages" aria-selected="false">Envíos</button>
                </div>
                <div className="tab-content bg-light p-5 w-70p" id="v-pills-tabContent">
                  <div className="tab-pane fade show active w-100" id="v-pills-profile" role="tabpanel" aria-labelledby="v-pills-profile-tab" tabIndex="0">
                   
                      {product.prod_info}
                  </div>
                  <div className="tab-pane fade w-100" id="v-pills-home" role="tabpanel" aria-labelledby="v-pills-home-tab" tabIndex="0">
                    {product.descripcion_producto}
                  </div>
                  <div className="tab-pane fade w-100" id="v-pills-messages" role="tabpanel" aria-labelledby="v-pills-messages-tab" tabIndex="0">...</div>
                </div>
              </div>
            </div>
          </section>
        </div>
        <div>
          <section className="py-2 bg-white w-100">
              <h3>Productos relacionados</h3>
              <div></div>
          </section>
        </div>
      </>


  );
};

export default ProductDetail;