import React, { useState, useEffect } from 'react';
export const Newproduct = () => {

  const [formData, setFormData] = useState({
    name_prod: "",
    price_prod: 0,
    ganancia_producto: 0,
    sale_prod: 0,
    tax_prod: 0,
    moneda_producto: "ARS",
    stock_prod: 0,
    father_cat: "",
    cod_prod: "",
    info_prod: "",
    desc_prod: "",
    attribute: [],
    fileUpload: [],
  });



  const handleInputChange = (e) => {
  const { name, value, type } = e.target;
  
  // Si el tipo es número, convierte el valor a número
  const parsedValue = type === 'number' ? Number(value) : value;
  
  setFormData((prevData) => ({ ...prevData, [name]: parsedValue }));
};

  const handleFileChange = (e) => {
    setFormData((prevData) => ({
      ...prevData,
      fileUpload: e.target.files,
    }));
  };

  const handleAttributeChange = (e) => {
    const { value, checked } = e.target;
    if (checked) {
      setFormData((prevData) => ({
        ...prevData,
        attribute: [...prevData.attribute, value],
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        attribute: prevData.attribute.filter((attr) => attr !== value),
      }));
    }
  };

const form = new FormData();
const handleSubmit = (e) => {
  e.preventDefault();
  
  
  for (const key in formData) {
    if (key === "fileUpload") {
      Array.from(formData.fileUpload).forEach((file) =>
        form.append("fileUpload[]", file)
      );
    } else {
      form.append(key, formData[key]);
    }
  }
};


  fetch("https://testreact.tdestudiodesign.com/backend/index.php?type=products&method=post", {
  method: "POST",
  body: form
})
.then(response => {
   const contentType = response.headers.get("content-type");
   if (contentType && contentType.includes("application/json")) {
       return response.json();
   } else {
       return response.text();  // Ver respuesta en HTML si no es JSON
   }
})
.then(data => {
   if (typeof data === 'string') {
      console.log("Respuesta HTML del servidor:", data);  // Ver respuesta HTML
   } else {
      console.log("Respuesta JSON del servidor:", data);  // Ver respuesta JSON
   }
})
.catch(error => console.error("Error:", error));




  return (
    <div className="prod px-5 mx-5">
      <form id="new_prod" encType="multipart/form-data" onSubmit={handleSubmit}>
        <div>
          
          <input
            type="text"
            className="w-100"
            placeholder='Nombre de Producto'
            name="name_prod"
            value={formData.name_prod}
            onChange={handleInputChange}
          />
        </div>
        <br />
        <div className="dual">
          <section className="d-flex flex-row-reverse justify-content-center py-2 w-100">
            <div className="mx-3 w-100">
              <div className="d-flex align-items-start w-100">
                <div className="accordion nav w-100 flex-column justify-content-around nav-pills me-3 bg-white p-5 h-70" id="accordionExample">

                  <div className="accordion-item">
                    <h2 className="accordion-header">
                    <button className="accordion-button bg-primary text-white fw-bold border-hover show" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                      <div className="d-flex align-content-center flex-column w-100 align-items-center"><span className='fs-3'>Precio</span><br/><span className='fs-6'> Precio de Costo | Ganancia | Descuento | Impuesto | Moneda</span></div>
                    </button>
                    
                    </h2>
                    <div id="collapseOne" className="accordion-collapse collapse show" data-bs-parent="#accordionExample">
                      <div className="accordion-body py-5 d-flex flex-row align-items-center justify-content-around flex-wrap">


                        <div className="price border-end border-secondary px-2 d-flex flex-column align-items-center justify-content-start flex-wrap">
                          <h5 className="text-start">Precio de Costo $</h5>
                          <input
                            type="number"
                            name="price_prod"
                            value={formData.price_prod}
                            onChange={handleInputChange}
                          />
                        </div>
                        <br />
                        <div className="descuento border-end border-secondary px-2 d-flex flex-column align-items-center justify-content-start flex-wrap">
                          <h5 className="text-start">Ganancia %</h5>
                          <input
                            type="number"
                            name="ganancia_producto"
                            value={formData.ganancia_producto}
                            onChange={handleInputChange}
                          />
                        </div>
                        <br />
                        <div className="descuento border-end border-secondary px-2 d-flex flex-column align-items-center justify-content-start flex-wrap">
                          <h5 className="text-start">Descuento %</h5>
                          <input
                            type="number"
                            name="sale_prod"
                            value={formData.sale_prod}
                            onChange={handleInputChange}
                          />
                        </div>
                        <br />
                        <div className="impuesto border-end border-secondary px-2 d-flex flex-column align-items-center justify-content-start flex-wrap">
                          <h5 className="text-start">Impuesto %</h5>
                          <input
                            type="number"
                            name="tax_prod"
                            value={formData.tax_prod}
                            onChange={handleInputChange}
                          />
                        </div>
                        <br />
                        <div className="impuesto d-flex flex-column align-items-center justify-content-start flex-wrap">
                          <h5 className="text-start">Moneda</h5>
                          <select
                            name="moneda_producto"
                            value={formData.moneda_producto}
                            onChange={handleInputChange}
                          >
                            <option value="ARS">ARS</option>
                            <option value="USD">USD</option>
                            <option value="USD_MEP">USD Mep</option>
                            <option value="USD_CCL">USD CCL</option>
                            <option value="USD_BLUE">USD BLUE</option>
                            <option value="EURO">EURO</option>
                          </select>
                        </div>



                      </div>
                    </div>
                  </div>

                  <div className="accordion-item">
                    <h2 className="accordion-header">
                    <button className="accordion-button bg-primary text-white fw-bold border-hover collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="true" aria-controls="collapseTwo">
                      <div className="d-flex align-content-center flex-column w-100 align-items-center"><span className='fs-3'>Inventario</span><br/><span className='fs-6'> Codigo de producto | Stock | Estado de inventario</span></div>

                    </button>
                    </h2>
                    <div id="collapseTwo" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                      <div className="accordion-body py-5 d-flex flex-row align-items-center justify-content-center flex-wrap">

                        <div className="stock border-end border-secondary px-4 d-flex flex-column align-items-center justify-content-start flex-wrap">
                          <h5 className="text-start">Cantidad Stock</h5>
                          <input
                            type="number"
                            name="stock_prod"
                            value={formData.stock_prod}
                            onChange={handleInputChange}
                          />
                        </div>
                        <br />
                        <div className="codigo border-end border-secondary px-4 d-flex flex-column align-items-center justify-content-start flex-wrap">
                          <h5 className="text-start">Estado de Stock</h5>
                          <select
                            name="father_cat"
                            value={formData.father_cat}
                            onChange={handleInputChange}
                          >
                            <option value="1">En Stock</option>
                            <option value="2">Agotado</option>
                            <option value="3">Para Reserva</option>
                          </select>
                        </div>
                        <br />
                        <div className="codigo px-4 d-flex flex-column align-items-center justify-content-start flex-wrap">
                          <h5 className="text-start">Código</h5>
                          <input
                            type="text"
                            name="cod_prod"
                            value={formData.cod_prod}
                            onChange={handleInputChange}
                          />
                        </div>




                      </div>
                    </div>
                  </div>

                  <div className="accordion-item">
                    <h2 className="accordion-header">
                    <button className="accordion-button bg-primary text-white fw-bold border-hover collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="true" aria-controls="collapseThree">
                      <div className="d-flex align-content-center flex-column w-100 align-items-center"><span className='fs-3'>Categoría de producto</span><br/><span className='fs-6'>Eleccion de Categoría</span></div>

                    </button>
                    </h2>
                    <div id="collapseThree" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                      <div className="accordion-body">

                        <div className="select d-flex flex-column align-items-center justify-content-start flex-wrap">
                          <div className="title">
                            <h3>Categoría Producto</h3>
                            <button
                              className="father_cat"
                              onClick={() =>
                                window.location.href = "./../new/new_category.php"
                              }
                            >
                              Añadir categoría
                            </button>
                          </div>
                          <br />
                          <select
                            name="father_cat"
                            value={formData.father_cat}
                            onChange={handleInputChange}
                          >
                            <option value="">Ninguna</option>
                            {/* Aquí reemplaza con un array de categorías */}
                          </select>
                        </div>




                      </div>
                    </div>
                  </div>

                  <div className="accordion-item">
                    <h2 className="accordion-header">
                    <button className="accordion-button bg-primary text-white fw-bold border-hover collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="true" aria-controls="collapseFour">
                      <div className="d-flex align-content-center flex-column w-100 align-items-center"><span className='fs-3'>Atributos de producto</span><br/><span className='fs-6'>Eleccion de Atributos</span></div>
                    </button>
                    </h2>
                    <div id="collapseFour" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                      <div className="accordion-body">


                        <div className="select d-flex flex-column align-items-center justify-content-start flex-wrap">
                          <div className="title">
                            <h3>Atributos de Productos</h3>
                            <button
                              className="father_cat"
                              onClick={() =>
                                window.location.href = "./../new/new_category.php"
                              }
                            >
                              Añadir atributo
                            </button>
                          </div>
                          <br />
                          <label>
                            <input
                              type="checkbox"
                              name="attribute[]"
                              value="value1"
                              checked={formData.attribute.includes("value1")}
                              onChange={handleAttributeChange}
                            />{" "}
                            Value 1
                          </label>
                          <br />
                          <label>
                            <input
                              type="checkbox"
                              name="attribute[]"
                              value="value2"
                              checked={formData.attribute.includes("value2")}
                              onChange={handleAttributeChange}
                            />{" "}
                            Value 2
                          </label>
                          <br />
                          <label>
                            <input
                              type="checkbox"
                              name="attribute[]"
                              value="value3"
                              checked={formData.attribute.includes("value3")}
                              onChange={handleAttributeChange}
                            />{" "}
                            Value 3
                          </label>
                          <br />
                          <label>
                            <input
                              type="checkbox"
                              name="attribute[]"
                              value="value4"
                              checked={formData.attribute.includes("value4")}
                              onChange={handleAttributeChange}
                            />{" "}
                            Value 4
                          </label>
                        </div>



                      </div>
                    </div>
                  </div>

                  <div className="accordion-item">
                    <h2 className="accordion-header">
                    <button className="accordion-button bg-primary text-white fw-bold border-hover collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="true" aria-controls="collapseFive">
                      <div className="d-flex align-content-center flex-column w-100 align-items-center"><span className='fs-3'>Información Técnica</span><br/><span className='fs-6'> Descripción de informacion tecnica sobre el producto</span></div>

                    </button>
                    </h2>
                    <div id="collapseFive" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                      <div className="accordion-body">
                          <h3>Información Técnica</h3>
                          <br />
                          <textarea
                            id="info_prod"
                            name="info_prod"
                            rows="20"
                            cols="75"
                            value={formData.info_prod}
                            onChange={handleInputChange}
                          />
                      </div>
                    </div>
                  </div>

                  <div className="accordion-item">
                    <h2 className="accordion-header">
                    <button className="accordion-button bg-primary text-white fw-bold border-hover collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSix" aria-expanded="true" aria-controls="collapseSix">
                      Descripción
                    </button>
                    </h2>
                    <div id="collapseSix" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                      <div className="accordion-body">


                        <h3>Descripción</h3>
                        <br />
                        <textarea
                          id="desc_prod"
                          name="desc_prod"
                          rows="20"
                          cols="75"
                          value={formData.desc_prod}
                          onChange={handleInputChange}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="accordion-item">
                    <h2 className="accordion-header">
                    <button className="accordion-button bg-primary text-white fw-bold border-hover collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSeven" aria-expanded="true" aria-controls="collapseSeven">
                      <div className="d-flex align-content-center flex-column w-100 align-items-center"><span className='fs-3'>Imágenes</span><br/><span className='fs-6'>Principal | Galeria</span></div>

                    </button>
                    </h2>
                    <div id="collapseSeven" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                      <div className="accordion-body">
                        <h3>Imagen Principal</h3>
                        <img
                          className="border border-primary p-4 border-opacity-25 border-3rounded"
                          src="https://safewebestudio.com/admin/service/products/images/Placeholder.png"
                          width="250"
                          alt="placeholder"
                        />
                        <br />
                        <input
                          type="file"
                          name="fileUpload[]"
                          onChange={handleFileChange}
                        />
                        <br /><br /><br />
                        <h3>Galería Imágenes</h3>
                        <br />
                        <div className="border rounded border-primary p-4 border-opacity-25 border-3">
                          {[...Array(4)].map((_, index) => (
                            <div className="d-flex" key={index}>
                              <img
                                src="https://safewebestudio.com/admin/service/products/images/Placeholder.png"
                                width="100"
                                alt={`placeholder-${index}`}
                              />
                              <input
                                type="file"
                                id={`profile-${index}`}
                                name="fileUpload[]"
                                accept="image/png, image/jpeg"
                                onChange={handleFileChange}
                              />
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>


                </div>

            </div>
            </div>
          </section>
        </div>
        <button type="submit">Guardar Producto</button>
      </form>
    </div>
  );

}
 

export default Newproduct;