//import logo from './logo.svg';
import './App.css';




//import Component from './Component';
import Header from './Header';
import Footer from './Footer';
import { Routes, Route } from "react-router-dom"

import  { Home }  from './pages/Home';
import  { Usuario }  from './pages/Usuario';
import   {Producto}   from './pages/Producto';
import   {CategoryList}   from './pages/Categoria';
import {ProductDetail} from './pages/ProductDetail';
import {Cart} from './pages/Cart';
import {Checkout} from './pages/Checkout';
import {Adminhome} from './admin/Adminhome';
import {Newproduct} from './admin/Producto';
//import {ErrorBoundary} from './pages/ErrorBoundary';



function App() {
  return (
    <div className="App">
      <Header />
        <Routes>
          <Route path="/" element={ <Home /> } />
          <Route path="Usuario" element={ <Usuario /> } />
          <Route path="/producto" element={<ProductDetail />} />
          <Route path="Producto" element={ <Producto /> } />
          <Route path="Categoria" element={ <CategoryList /> } />
          <Route path="cart" element={ <Cart /> } />
          <Route path="Checkout" element={ <Checkout /> } />
          <Route path="admin" element={ <Adminhome /> } />
          <Route path="admin/Producto" element={ <Newproduct /> } />

        </Routes>
        <Footer />
    </div>
  );
}

export default App;
